export const modalScript = () => {
  const openModals = document.querySelectorAll("[data-show-modal]");
  openModals.forEach((openModal) => {
    openModal.addEventListener("click", () => {
      const modalToShow = openModal.dataset.showModal;
      document
        .querySelector(`[data-modal="${modalToShow}"]`)
        ?.classList?.add("stories-modal--show");
    });
  });

  const closeModal = document.querySelectorAll(".button-close");
  closeModal.forEach((buttonClose) => {
    buttonClose.addEventListener("click", () => {
      document.querySelector(".stories-modal--show")?.classList?.remove("stories-modal--show");
    });
  });
};
