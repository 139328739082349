import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway", "Helvetica Neue", "Arial", "Helvetica", "Geneva", "sans-serif"].join(
      ","
    ),
  },
  palette: {
    primary: {
      main: "#00b1f0",
      light: "#F6FCFE",
    },
    secondary: {
      main: "#8c8c8c", // before 4a4a49
    },
    text: {
      primary: "#4a4a49",
    },
  },
});

export default theme;
