/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { withPrefix } from "gatsby";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import theme from "./src/components/theme";
import { VideoProvider, VideoPageWrapper } from "./src/context/video";
import { UserProvider } from "./src/context/user";
import { onRouteUpdate as updateScrollOnRouteUpdate } from "./src/hooks/use-keep-scroll";
import {
  insertChannelsightScript,
  removeChannelsightScript,
  closeCsWidget,
  patchWtbCss,
} from "./src/hooks/use-where-to-buy";
import { initCookiePolicy, initPrivacyPolicy } from "./src/utility/onetrust";
import "./src/css/main.scss";
import smoothscroll from "smoothscroll-polyfill";
import { productFilterEvent } from "./src/utility/filters-event";
import { eventuallyRemoveReevooScript } from "./src/utility/reevoo";
import { modalScript } from "./src/utility/modal-script";
smoothscroll.polyfill();

const attachShadow = HTMLElement.prototype.attachShadow;

HTMLElement.prototype.attachShadow = function (option) {
  const sh = attachShadow.call(this, option);
  // console.info("%s shadow attached to %s", option.mode, this);
  const event = new CustomEvent("attach-shadow", {
    detail: {
      element: this,
    },
  });
  document.dispatchEvent(event);
  //add a MutationObserver here
  return sh;
};

export const onRouteUpdate = (params) => {
  updateScrollOnRouteUpdate(params);
  initCookiePolicy();
  initPrivacyPolicy();
  modalScript();
  // Non eseguire sul primo caricamento, viene fatto da onInitialClientRender
  if (params.prevLocation) {
    closeCsWidget();
    removeChannelsightScript();
    insertChannelsightScript();
    if (params.location.pathname !== params.prevLocation.pathname) {
      eventuallyRemoveReevooScript();
      const event = new CustomEvent("RouteChange", {
        detail: {
          slug: params.location.pathname,
        },
      });
      document.dispatchEvent(event);
    }
  } else {
    if (params.location.search) {
      const parameters = new URLSearchParams(params.location.search);
      const utmCampaign = parameters.get("utm_campaign");
      const utmMedium = parameters.get("utm_medium");
      const utmSource = parameters.get("utm_source");
      const utmContent = parameters.get("utm_content");
      const utmTerm = parameters.get("utm_term");

      if (utmCampaign) {
        setUtmValue(utmCampaign, "campaign");
      }
      if (utmContent) {
        setUtmValue(utmContent, "content");
      }
      if (utmSource) {
        setUtmValue(utmSource, "source");
      }
      if (utmMedium) {
        setUtmValue(utmMedium, "medium");
      }
      if (utmTerm) {
        setUtmValue(utmTerm, "term");
      }
    }
    productFilterEvent(params);
  }
};

const setUtmValue = (value, label) => {
  const utmStorage = sessionStorage.getItem(`utm_${label}`);
  if (!utmStorage) {
    sessionStorage.setItem(`utm_${label}`, value);
  }
};

export const onInitialClientRender = () => {
  const isIeBrowser = !!window.MSCompatibleInfo;
  if (isIeBrowser) {
    window.location.replace(withPrefix("browsernotsupported"));
  }
  insertChannelsightScript();
  patchWtbCss();
};

export const wrapRootElement = ({ element }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <UserProvider>
          <VideoProvider>{element}</VideoProvider>
        </UserProvider>
      </HelmetProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export const wrapPageElement = ({ element }) => {
  return <VideoPageWrapper>{element}</VideoPageWrapper>;
};
