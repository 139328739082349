import React from "react";
import { Link as GatsbyLink } from "gatsby";
import classNames from "classnames";
import { normalizeRelativePath } from "../../utility/paths";

// Fine tune this on project needs
const INTERNAL_REGEX = /^(?!#)(?!\w+:)\/?(?!\/)/;

const GlobalLink = ({ to, children, ...props }) => {
  if (!to) {
    return (
      <span {...props}>
        <>{children}</>
      </span>
    );
  }
  // eslint-disable-next-line no-script-url
  if (to === "javascript:Optanon.ToggleInfoDisplay()") {
    const { className, target, ...otherProps } = props;
    return (
      <button
        {...otherProps}
        className={classNames("link", className)}
        onClick={() => {
          if (window.Optanon) {
            window.Optanon.ToggleInfoDisplay();
          } else {
            console.warn("window.Optanon not available");
          }
        }}
      >
        {children}
      </button>
    );
  }

  const forcedTarget = Array.isArray(props.target) ? props.target[0] : props.target;
  const linkTarget = forcedTarget ? forcedTarget : to.startsWith("#") ? "_self" : "_blank";
  const target = linkTarget.startsWith("_") ? linkTarget : `_${linkTarget}`;

  if (to && INTERNAL_REGEX.test(to)) {
    const { className, activeClassName, style, activeStyle } = props;

    // Gestione activeClassName indipendente da slash finale o meno
    const isActive = ({ isPartiallyCurrent, href, location }) => {
      const pathname = location.pathname;
      const strippedHref = href.endsWith("/") ? href.substring(0, href.length - 1) : href;
      const strippedPathname = pathname.endsWith("/")
        ? pathname.substring(0, pathname.length - 1)
        : pathname;
      if (strippedHref === strippedPathname) {
        return {
          className: [className, activeClassName].filter(Boolean).join(" "),
          style: { ...style, ...activeStyle },
        };
      }
      return null;
    };
    const internalLink = to ? normalizeRelativePath(to) : to;
    return (
      <GatsbyLink getProps={isActive} to={internalLink} {...props} target={target}>
        <>{children}</>
      </GatsbyLink>
    );
  }
  // Remove activeClassName prop from external links
  const { activeClassName, ...other } = props;
  return (
    <a href={to} rel="noopener noreferrer" {...other} target={target}>
      <>{children}</>
    </a>
  );
};

export const Link = GlobalLink;
