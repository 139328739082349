import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { withPrefix } from "gatsby";

const UserContext = createContext({
  userData: undefined,
  logout: () => 0,
});

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState();

  const checkUser = useCallback(async () => {
    const prefix = withPrefix("/").slice(1, -1);
    try {
      const response = await fetch(`/headless-api/oauth/user?prefix=${prefix}`);
      if (!response.ok) throw new Error(response.statusText);
      const data = await response.json();
      console.log("data: ", data);
      if (data.user) {
        setUserData(data.user);
        return;
      }
    } catch (err) {
      console.error("error in get user: ", err);
    }
    setUserData(null);
  }, []);

  const logout = useCallback(async () => {
    const prefix = withPrefix("/").slice(1, -1);
    try {
      const response = await fetch(`/headless-api/oauth/logout`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prefix }),
      });
      if (!response.ok) throw new Error(response.statusText);

      const data = await response.json();
      console.log("data: ", data);
      sessionStorage.removeItem("sf_data");
      setUserData(null);
    } catch (err) {
      console.error("error in logout: ", err);
    }
  }, []);

  useEffect(() => {
    const sfData = sessionStorage.getItem("sf_data");
    if (sfData) {
      sessionStorage.removeItem("sf_data");
      // console.log('sfData: ', sfData);
      try {
        const userDt = JSON.parse(sfData);
        setUserData(userDt);
      } catch (err) {
        console.error("invalid user data: ", err);
        setUserData(null);
      }
    } else {
      checkUser();
    }
  }, [checkUser]);

  const contextValue = useMemo(() => {
    return { userData, logout };
  }, [userData, logout]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserContext;
