export const productDetailView = (
  babelProdotto,
  productRegularPrice,
  productSpecialPrice,
  origin,
  config
) => {
  const { key_prod, productName, pageLine } = babelProdotto;
  const regularPrice = productRegularPrice ? productRegularPrice : null;
  const specialPrice = productSpecialPrice
    ? productSpecialPrice
    : productRegularPrice
    ? productRegularPrice
    : null;
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_item", //per trigger in SPA
    ecommerce: {
      currency: config?.currency?.value || "EUR",
      value: specialPrice,
      items: [
        {
          item_name: productName, // Name or ID is required.
          item_id: key_prod,
          price: regularPrice,
          item_brand: "Candy",
          item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
          item_list_name: origin ? origin : `Product Detail page`,
          item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : `Product Detail page`,
          discount:
            specialPrice && regularPrice
              ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
              : 0,
          index: 0,
          quantity: 1,
        },
      ],
    },
  });
};

export const productClick = (babelProdotto, origin, index) => {
  if (!window.dataLayer) return;

  try {
    const { key_prod, productName, pageLine, category, productRegularPrice, productSpecialPrice } =
      babelProdotto;
    const regularPrice = productRegularPrice ? productRegularPrice : null;
    const specialPrice = productSpecialPrice
      ? productSpecialPrice
      : productRegularPrice
      ? productRegularPrice
      : null;
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_name: origin ? origin : "Other",
        item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : "other",
        items: [
          {
            item_name: productName,
            item_id: key_prod,
            item_brand: "Candy",
            price: regularPrice,
            item_list_name: origin ? origin : "Other",
            item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : "other",
            index: index,
            quantity: 1,
            item_category: category
              ? category
              : pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
            discount:
              specialPrice && regularPrice
                ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
                : 0,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productClick: ", err);
  }
};

export const productFilters = (filterEvent) => {
  if (!window.dataLayer) {
    console.log("filterEvent", filterEvent);
    return;
  }

  try {
    window.dataLayer.push(filterEvent);
  } catch (err) {
    console.error("Error in productFilters: ", err);
  }
};

export const productImpression = (products, origin) => {
  const list = products.map((item, index) => {
    const { key_prod, productName, pageLine, productRegularPrice, productSpecialPrice } = item;
    const regularPrice = productRegularPrice ? productRegularPrice : null;
    const specialPrice = productSpecialPrice
      ? productSpecialPrice
      : productRegularPrice
      ? productRegularPrice
      : null;
    return {
      item_name: productName,
      item_id: key_prod,
      item_brand: "Candy",
      price: regularPrice,
      item_list_name: origin ? origin : "Other",
      item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : "other",
      index: index,
      quantity: 1,
      item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
      discount:
        specialPrice && regularPrice
          ? parseFloat((Number(regularPrice) - Number(specialPrice)).toFixed(2))
          : 0,
    };
  });
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      item_list_id: origin ? origin.toLowerCase().replaceAll(" ", "_") : "other",
      item_list_name: origin ? origin : "Other",
      items: list,
    },
  });
};

export const landingImpression = (slug) => {
  if (!window.dataLayer) return;
  window.dataLayer.push({
    event: "LP",
    slug,
  });
};

export const eventImpression = (slug, eventCategory) => {
  if (!window.dataLayer) return;
  window.dataLayer.push({
    event: eventCategory,
    slug,
  });
};

export const eventPdpSliderSlide = (data) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "pdp-slider-slide",
      data,
    });
  } catch (err) {
    console.error("Error in eventPdpSliderSlide: ", err);
  }
};

export const eventPdpSliderClick = (data) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "pdp-slider-click",
      data,
    });
  } catch (err) {
    console.error("Error in eventPdpSliderClick: ", err);
  }
};
