/**
 * VideoOverlay
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class VideoOverlay
 * @param {object} props - Props
 */

import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import Button from "../atoms/button";
import { onetrustShowSettings } from "../../utility/onetrust";

const GROUPS = ["C0003"];
const areCookiesEnabled = () =>
  window.OnetrustActiveGroups && GROUPS.some((item) => window.OnetrustActiveGroups.includes(item));

const VideoOverlay = ({ show, url, isYoutube, close }) => {
  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        close
        youtubeNotEnabled
        customize
      }
    }
  `);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (show && isYoutube && !areCookiesEnabled()) {
      setShowAlert(true);
      if (window.OneTrust) {
        window.OneTrust.OnConsentChanged(() => {
          setShowAlert(!areCookiesEnabled());
        });
      }
      return () => setShowAlert(false);
    }
  }, [show, isYoutube]);
  return (
    <div className={classNames("videoPlayer", { "videoPlayer--visible": show })}>
      <div className="video">
        <button className="close" onClick={close}>
          {labelManagerLabels.close}
        </button>
        {url?.endsWith(".mp4") ? (
          <video
            className="video-no-border"
            playsInline
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            src={url}
          ></video>
        ) : (
          <div className="videoPlayer__container">
            {showAlert ? (
              <div className="videoPlayer__alert">
                <div className="videoPlayer__alert-title">
                  {labelManagerLabels.youtubeNotEnabled}
                </div>
                <div className="videoPlayer__alert-buttons">
                  <Button
                    label={labelManagerLabels.customize}
                    onClick={onetrustShowSettings}
                    className="primary"
                    color="white"
                  />
                  <Button
                    label={labelManagerLabels.close}
                    onClick={close}
                    className="primary"
                    color="white"
                  />
                </div>
              </div>
            ) : (
              <iframe
                // className="videoPlayer--frame"
                title="Candy"
                width="640"
                height="360"
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              ></iframe>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoOverlay;
