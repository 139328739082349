/**
 * Icon
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Icon
 * @param {object} props - Props
 */

import React from "react";

const Icon = ({ name, color, url, width, height, className, alt }) =>
  name === "custom" ? (
    <span className={`icon custom ${className || ""}`}>
      <img alt={alt || ""} src={url} style={{ width: `${width}px`, height: `${height}px` }} />
    </span>
  ) : (
    <span className={`icon icon-${name} ${color} ${className || ""}`}>
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  );

export default Icon;
