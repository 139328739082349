import React, { createContext, useState, useCallback, useMemo, useContext } from "react";
import VideoOverlay from "../components/components/video-overlay";

const VideoContext = createContext({ showVideoModal: () => undefined });

export const VideoProvider = ({ children }) => {
  const [videoState, setVideoState] = useState({ videoId: "", url: "", show: false });
  const showVideoModal = useCallback((videoUrl) => {
    // const { name: videoUrl } = e.currentTarget;
    const youtubeMatch = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(
      videoUrl
    );
    // const url = !youtubeMatch && videoUrl.includes('/') ? videoUrl : '';
    const videoId = youtubeMatch && youtubeMatch[2].length === 11 ? youtubeMatch[2] : null;
    const url = videoUrl.endsWith(".mp4")
      ? videoUrl
      : `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    setVideoState({ videoId, url, show: true });
  }, []);

  const closeVideoModal = useCallback(() => {
    setVideoState((current) => (current.show ? { ...current, show: false } : current));
  }, []);

  const videoPlayerVisible = !!videoState.url && videoState.show;
  const data = useMemo(
    () => ({ videoPlayerVisible, videoState, showVideoModal, closeVideoModal }),
    [videoPlayerVisible, showVideoModal, videoState, closeVideoModal]
  );

  return <VideoContext.Provider value={data}>{children}</VideoContext.Provider>;
};

export const VideoPageWrapper = ({ children }) => {
  const { videoPlayerVisible, videoState, closeVideoModal } = useContext(VideoContext);
  return (
    <>
      {children}
      <VideoOverlay
        show={videoPlayerVisible}
        close={closeVideoModal}
        url={videoState.show ? videoState.url : ""}
        isYoutube={!!videoState?.videoId}
      />
    </>
  );
};

export default VideoContext;
