import { withPrefix } from "gatsby";
import lineConfig from "../../babel-config.json";
import { retrieveOneTrust } from "./onetrust";

let reevooScriptInserted = false;
const reevoGroups = ["C0003"];

export const areEnabledReevooCookies = () => {
  return (
    window.OnetrustActiveGroups &&
    reevoGroups.some((item) => window.OnetrustActiveGroups.includes(item))
  );
};

const PATH_PREFIX = withPrefix("/").slice(1, -1) || process.env.PATH_PREFIX;
const businessRegion = lineConfig.businessRegion[PATH_PREFIX];

const insertReevooScript = () => {
  if (businessRegion) {
    const reevooScript = document.createElement("script");
    reevooScript.id = "reevoo-loader";
    reevooScript.setAttribute("src", `https://api.feefo.com/api/javascript/${businessRegion}`);
    reevooScript.setAttribute("async", "");
    document.head.appendChild(reevooScript);
  } else {
    console.log("Missing business region for this country");
  }
};

let reevooStarted;
const setupOneTrust = (OneTrust) => {
  OneTrust.OnConsentChanged((e) => {
    // console.log('Onetrust change event: ', e);
    if (!reevooStarted && areEnabledReevooCookies()) {
      reevooStarted = true;
      insertReevooScript();
    }
  });
  if (OneTrust.IsAlertBoxClosed() && areEnabledReevooCookies()) {
    insertReevooScript();
  }
};

export const eventuallyInsertReevooScript = async () => {
  if (!reevooScriptInserted) {
    reevooScriptInserted = true;
    if (process.env.NODE_ENV === "development") {
      insertReevooScript();
    } else {
      const oneTrust = await retrieveOneTrust();
      if (oneTrust) {
        setupOneTrust(oneTrust);
      }
    }
  }
};

export const eventuallyRemoveReevooScript = () => {
  const rootScript = document.querySelector("#reevoo-loader");
  const widgetScript = document.querySelector(
    '[src="//register.feefo.com//feefo-widget/js/feefo-widget.js"]'
  );
  const feefoScripts = document.querySelectorAll('[src*="//register.feefo.com//feefo-widget/js/"');
  const feefoCss = [...document.querySelectorAll("style")].filter((item) =>
    item.innerText?.includes("font-family:FWFontAwesome")
  );
  const allElements = [rootScript]
    .concat(widgetScript)
    .concat([...feefoScripts])
    .concat([...feefoCss])
    .filter(Boolean);
  if (allElements.length) {
    allElements.forEach((element) => {
      element.remove();
    });
    reevooScriptInserted = false;
  }
};
