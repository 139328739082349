/**
 * Button
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Button
 * @param {object} props - Props
 */

import React, { useCallback, useContext } from "react";
import { Link } from "./link";
import Icon from "./icon";
import VideoContext from "../../context/video";

const Button = ({ link, label, target, className, color, type, videourl, disabled, ...other }) => {
  const { showVideoModal } = useContext(VideoContext);
  const openVideo = useCallback(() => showVideoModal(videourl), [videourl, showVideoModal]);

  return type === "video" ? (
    <button className={`button ${className} ${color} video`} onClick={!disabled ? openVideo : null}>
      {label}
      <Icon name={"system-play"} color={"white"} />
    </button>
  ) : disabled || !link ? (
    <button {...other} disabled={disabled} className={`button ${className} ${color}`}>
      {type === "compare" ? <span className="i"></span> : null}
      {label}
      {type === "arrow" ? <span className="i"></span> : null}
      {/* PS - AGGIUNTO PLUS */}
      {type === "plus" ? <span className="icon-redesign-plus"></span> : null}
    </button>
  ) : (
    <Link
      {...other}
      disabled={disabled}
      to={disabled ? null : link}
      target={target}
      className={`button ${className || ""} ${color || ""}`}
    >
      {type === "compare" ? <span className="i"></span> : null}
      {label}
      {type === "arrow" ? <span className="i"></span> : null}
    </Link>
  );
};

export default Button;
